import React from 'react'
import Img from 'gatsby-image'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Link } from 'gatsby'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { validate } from 'email-validator'

import Cookie from './cookie'

export default class extends React.Component {
  cookie = new Cookie()

  constructor(props) {
    super(props)

    this.state = { isPopupOpen: false, email: '', status: '', msg: '' }
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.handlePopupOpen()
      }.bind(this),
      9000
    )
  }

  handlePopupOpen = event => {
    if (!this.cookie.getCookie('SloopTools_Newsletter_Popup')) {
      this.setState({ isPopupOpen: true })
    }
  }

  handlePopupClose = event => {
    this.setState({ isPopupOpen: false })
    this.cookie.setCookie(
      'SloopTools_Newsletter_Popup',
      'closed',
      this.cookie.COOKIE_LIFETIME_IN_MONTHS_CLOSED
    )
  }

  handleFormSubmit = async e => {
    e.preventDefault()
    e.stopPropagation()

    if (!validate(this.state.email)) {
      this.setState({
        status: 'error',
        msg: this.props.translations.invalidAddress,
      })
      return
    }

    this.setState({ status: 'waiting' })

    try {
      const result = await addToMailchimp(this.state.email)

      if (result.result === 'success') {
        this.setState({ status: 'success' })
        setTimeout(() => {
          this.setState({ isPopupOpen: false })
          this.cookie.setCookie(
            'SloopTools_Newsletter_Popup',
            'subscriptionSuccess',
            this.cookie.COOKIE_LIFETIME_IN_MONTHS_SUBSCRIPTION_SUCCESS
          )
        }, 3000)
      } else {
        this.setState({ status: 'error', msg: result.msg })
      }
    } catch (err) {
      this.setState({ status: 'error', msg: err })
    }
  }

  render() {
    const { img, translations: t, withLanguagePrefix } = this.props

    const makeTextWithLinks = list => {
      return (
        <span>
          {list.map((el, i) => (
            <span key={i}>
              {el.href ? (
                <Link to={withLanguagePrefix(el.href)}>{el.label}</Link>
              ) : (
                el
              )}
            </span>
          ))}
        </span>
      )
    }

    return (
      <ReactCSSTransitionGroup
        transitionName="slptls-fade"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {this.state.isPopupOpen ? (
          <div
            className="modal fade show"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="NewsLetterPopup"
            aria-hidden="true"
            style={{ display: 'block' }}
          >
            <div
              className="modal-dialog modal-dialog-centered"
              style={{ zIndex: 1050 }}
              role="document"
            >
              <div className="modal-content">
                <form onSubmit={this.handleFormSubmit} method="post" noValidate>
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      {t.popupHeader}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.handlePopupClose}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-5 d-none d-sm-block">
                        <Img sizes={img.sizes} />
                      </div>
                      <div className="col-xs-12 col-sm-7">
                        {this.state.status === 'success' ? (
                          <p>{t.success}</p>
                        ) : (
                          <div className="form-group h-100">
                            <div className="h-75">
                              {t.popupSubheader ? (
                                <p>{t.popupSubheader}</p>
                              ) : (
                                ''
                              )}
                              <input
                                type="email"
                                className={`form-control ${
                                  this.state.status === 'error'
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder={t.placeholder}
                                onChange={e => {
                                  this.setState({ email: e.target.value })
                                }}
                              />
                              {this.state.status === 'error' ? (
                                <div
                                  className="invalid-feedback"
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.msg,
                                  }}
                                />
                              ) : (
                                <small
                                  id="emailHelp"
                                  className="form-text text-muted"
                                >
                                  {makeTextWithLinks(t.notice)}
                                </small>
                              )}
                            </div>
                            <div className="h-25">
                              <div className="newsletter-popup-button">
                                <button
                                  type="submit"
                                  className="btn btn-primary slptls-mg-xs-bottom"
                                  onClick={this.handleFormSubmit}
                                  disabled={this.state.status === 'sending'}
                                >
                                  {t.buttonLabel}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="modal-backdrop fade show"
              onClick={this.handlePopupClose}
            />
          </div>
        ) : null}
      </ReactCSSTransitionGroup>
    )
  }
}
