import React, { useState } from 'react'

import platformImage from '../img/platform_graphic.png'
import storeImage from '../img/store_graphic.jpg'
import connectImage from '../img/connect_graphic.png'

const AccordionCard = ({ id, translations: t, image, className, onClick }) => (
  <li className={`${id} ${className}`} onMouseEnter={onClick}>
    <div class="handle">
      <h3 onClick={onClick}>
        <span>{t.header}</span>
      </h3>
    </div>
    <div class="content" style={{ backgroundImage: `url(${image})` }}>
      <h3>
        <span>{t.header}</span>
      </h3>
      <p>{t.accordion.text}</p>
    </div>
  </li>
)

const images = {
  platform: platformImage,
  store: storeImage,
  connect: connectImage,
}

export default ({ translations, data }) => {
  const [active, setActive] = useState(0)

  const getClassName = index =>
    index < active ? 'left' : index === active ? 'active' : 'right'

  return (
    <ul className="slptls-accordion">
      {['platform', 'store', 'connect'].map((key, index) => (
        <AccordionCard
          key={key}
          id={key}
          translations={translations[key]}
          image={images[key]}
          className={getClassName(index)}
          onClick={() => setActive(index)}
        />
      ))}
    </ul>
  )
}
