import React from 'react'
import { getUserLangKey } from 'ptz-i18n'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby'

import translations from '../i18n/translations'
import { languages, defaultLanguage } from '../i18n/languages'
import OnePager from '../components/OnePager'

import '../scss/bootstrap.scss'
import { Redirect } from '@reach/router'

export default ({ data, pageContext }) => {
  if (pageContext.unknownLanguage) {
    const userLanguage = getUserLangKey(
      languages.map(l => l.short),
      defaultLanguage
    )
    return <Redirect to={`/${userLanguage}/`} noThrow />
  }

  return (
    <OnePager data={data} translations={translations[pageContext.language]} />
  )
}

export const query = graphql`
  query indexQuery {
    images: allFile(filter: { absolutePath: { regex: "//img//" } }) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    products: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//products//" } }
      sort: { fields: [frontmatter___order] }
    ) {
      edges {
        node {
          frontmatter {
            title
            picture {
              sharp: childImageSharp {
                sizes(maxWidth: 538, quality: 80) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
            }
            link
          }
          excerpt
          html
        }
      }
    }
    news: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//news//" } }
      sort: { fields: [frontmatter___order] }
    ) {
      edges {
        node {
          frontmatter {
            title
            picture {
              sharp: childImageSharp {
                sizes(maxWidth: 538, quality: 80) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
            }
            link
          }
          excerpt
          html
        }
      }
    }
    partners: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//partners//" } }
    ) {
      edges {
        node {
          frontmatter {
            picture {
              sharp: childImageSharp {
                sizes(maxWidth: 160, quality: 80) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
              gray: childImageSharp {
                sizes(
                  maxWidth: 160
                  quality: 80
                  duotone: { highlight: "#ffffff", shadow: "#808080" }
                ) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
            }
            author
            company
          }
          excerpt
          html
        }
      }
    }
    team: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//team//" }
        frontmatter: {
          title: { ne: "" }
          subtitle: { ne: "" }
          # picture
        }
        html: { ne: "" }
      }
      sort: { fields: [frontmatter___order] }
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            picture {
              sharp: childImageSharp {
                sizes(maxWidth: 576, quality: 80) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
            }
          }
          fields {
            language
          }
          excerpt
          html
        }
      }
    }
    board: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//board//" }
        frontmatter: {
          title: { ne: "" }
          subtitle: { ne: "" }
          # picture
        }
        html: { ne: "" }
      }
      sort: { fields: [frontmatter___order] }
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            picture {
              sharp: childImageSharp {
                sizes(maxWidth: 576, quality: 80) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
            }
          }
          fields {
            language
          }
          excerpt
          html
        }
      }
    }
    jobs: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//jobs//" } }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            language
            slug
          }
        }
      }
    }
    heroImage: file(relativePath: { regex: "/hero.png/" }) {
      childImageSharp {
        sizes(maxWidth: 1920) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    storeHeroImage: file(relativePath: { regex: "/store_hero.png/" }) {
      childImageSharp {
        sizes(maxWidth: 1920) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    connectHeroImage: file(relativePath: { regex: "/connect_hero.png/" }) {
      childImageSharp {
        sizes(maxWidth: 1920) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    platformHeroImage: file(relativePath: { regex: "/platform_hero_2.png/" }) {
      childImageSharp {
        sizes(maxWidth: 1920) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    teamImage: file(relativePath: { regex: "/team.jpg/" }) {
      childImageSharp {
        sizes(maxWidth: 1920, quality: 85) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    supportImageDE: file(relativePath: { regex: "/EU_LE_14-20_BMDW_DE.JPG/" }) {
      childImageSharp {
        sizes(maxWidth: 1364, quality: 85) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    supportImageEN: file(relativePath: { regex: "/EU_LE_14-20_BMDW_EN.JPG/" }) {
      childImageSharp {
        sizes(maxWidth: 1364, quality: 85) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    efreImageDE: file(relativePath: { regex: "/EFRE_DE.png/" }) {
      childImageSharp {
        sizes(maxWidth: 650, quality: 85) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    efreImageEN: file(relativePath: { regex: "/EFRE_EN.png/" }) {
      childImageSharp {
        sizes(maxWidth: 600, quality: 85) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    newsletterImage: file(relativePath: { regex: "/Newsletter.png/" }) {
      childImageSharp {
        sizes(maxWidth: 300) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
