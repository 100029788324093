import React from 'react'
import Head from '../components/Head'
import Navbar from '../components/navbar'
import Hero from '../components/hero'
import Footer from '../components/footer'
import Anchor from '../components/Anchor'
import Newspopup from '../components/NewsletterPopup'
import content from './content'

import { languages } from '../i18n/languages'

// TEMP
import icon_presentation from '../img/icons/presentation.svg'
import Accordion from './Accordion'

export default ({ data, translations }) => {
  const menuItems = content.map(({ id }) => ({
    id,
    label: translations[id].menuLabel,
  }))

  const img = name => {
    const image = data.images.edges.find(({ node }) => node.name === name)
    return (image && image.node.publicURL) || icon_presentation
  }

  const withLanguagePrefix = path => `/${translations.lang}${path}`

  return (
    <div>
      <Head translations={translations.head} />
      <Navbar
        id="top"
        menuItems={menuItems}
        languageItems={languages.filter(
          ({ short }) => short !== translations.lang
        )}
        translations={translations.navbar}
        withLanguagePrefix={withLanguagePrefix}
      />
      <Accordion translations={translations} data={data} />
      {content.map(c => (
        <Anchor id={c.id} key={c.id}>
          <c.component
            translations={translations[c.id]}
            img={img}
            data={data}
            withLanguagePrefix={withLanguagePrefix}
            language={translations.lang}
          />
        </Anchor>
      ))}
      <Newspopup
        translations={translations.footer.newsletter}
        img={data.newsletterImage.childImageSharp}
        withLanguagePrefix={withLanguagePrefix}
      />
      <Footer
        id="footer"
        translations={translations.footer}
        menuItems={menuItems}
        withLanguagePrefix={withLanguagePrefix}
      />
    </div>
  )
}
